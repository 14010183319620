
@import url('https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap');

.footer_section {
    /* background-color: rgb(2, 17, 31); */
    background-color: rgb(5, 5, 5);
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    height: 100%;
    width: 100%;    
}

.footer_content {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: rgb(165, 158, 158);
    margin: 0 auto;
    padding: 2px 24px 2px;
}

.footer_gap {
    padding-left: 5px;
    padding-right: 5px;
}

.logo_img {
    height: 60px;
    margin: 24px 0 8px 0;
}

.back_to_top {    
    padding: 12px 0 24px; 
    margin: 0 auto; 
    cursor: pointer;;
}

.yellow {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: rgba(230, 213, 67, 1);
    text-decoration: none;
}

/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px) {
   
    .footer_content {
        text-align: center;
        padding: 12px 24px 12px;
    }
 
}