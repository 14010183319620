@import url('https://fonts.googleapis.com/css?family=Kaushan+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');
body {
    margin: 0;
}
.main_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    height: 100vh;
    width: 100vw;
}
.main_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;  
}
.main_section_content_container {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
    height: 80vh;
    max-width: 1200px;
    margin: 0 auto; 
}
.main_logo_space {
    margin: 0 auto;
}
.main_logo_img {
    height: 600px;
}
.main_first_line {
    font-family: 'Krona One', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 84px;
    text-shadow: 2px 2px black;
    line-height: 1.1;
    letter-spacing: 8px;
    text-align: center;
    margin: 24px 48px 12px;
}
.main_second_line {
    font-family: 'Krona One', sans-serif;
    color: rgba(230, 213, 67, 1);
    font-size: 84px;  
    text-shadow: 2px 2px black;      
    line-height: 1.1;
    text-align: center;
}
/* -------------------- LAPTOP ------------- */
@media screen and (max-width: 1500px) and (min-width: 1200.5px) {
    .main_section_content_container {
        width: 100vw;
        height: none;
        margin: 0 auto;
        padding: 0;
    }
    .main_logo_img {
        height: 400px;
        padding: 40px 0 0;
    }
    .main_first_line {
        font-size: 64px;
        line-height: 1.1;
        padding: 0 12px 0px;
        letter-spacing: 6px;
    }
    .main_second_line {
        font-size: 76px;        
        line-height: .8;
        padding: 6px 12px;
        text-shadow: 2px 2px black;
    }  
}
/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .main_section_content_container {
        width: 80vw;
        height: none;
        margin: 13vh auto;
    }
    .main_logo_img {
        height: 200px;
        padding: 40px 0 0;
    }
    .main_first_line {
        font-size: 55px;
        line-height: 1.1;
        padding: 12px 12px 0px;
        letter-spacing: 5px;
    }
    .main_second_line {
        font-size: 68px;        
        line-height: .8;
        padding: 6px 12px 48px;
        text-shadow: 2px 2px black;
    }  
}

/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991.5px) and (min-width: 768px){    
    .main_section_content_container {
        width: 80vw;
        height: none;
        margin: 13vh auto;
    }
    .main_logo_img {
        height: 150px;
        padding: 80px 0 0;
    }
    .main_first_line {
        font-size: 42px;
        line-height: 1.1;
        padding: 12px 12px 0px;
        letter-spacing: 5px;
    }
    .main_second_line {
        font-size: 58px;        
        line-height: .9;
        padding: 6px 12px 48px;
        text-shadow: 2px 2px black;
    }  

}
/* -------------------- LARGE PHONE------------- */
@media screen and (max-width: 767.5px) and (min-width: 480px){
    .main_section_content_container {
        width: 80vw;
        height: none;
        margin: 15vh auto;
    }
    .main_logo_img {
        height: 100px;
        padding: 40px 0 0;
    }
    .main_first_line {
        font-size: 30px;
        line-height: 1.1;
        padding: 12px 12px 0px;
        letter-spacing: 5px;
    }
    .main_second_line {
        font-size: 30px;        
        line-height: 1;
        padding: 6px 12px 48px;
        text-shadow: 2px 2px black;
    }    
}
/* -------------------- SMALL PHONES ------------- */
@media screen and (max-width: 479.5px) and (min-width: 319px){  
    
    .main_section_content_container {
        width: 80vw;
        height: none;
        margin: 15vh auto;
    }
    .main_logo_space {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
    }
    .main_logo_img {
        height: 90px; 
    }
    .main_first_line {
        font-family: 'Krona One', sans-serif;
        color: rgb(255, 255, 255);
        font-size: 24px;
        line-height: 1.1;
        letter-spacing: 2px;
        margin: 0 auto;
        padding: 8px 8px 0px;
    }
    .main_second_line {
        font-family: 'Krona One', sans-serif;
        color: rgba(230, 213, 67, 1);
        font-size: 24px;        
        line-height: 1.1;
        text-align: center;
        text-shadow: 2px 2px black;
    }
}


