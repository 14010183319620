@import url('https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');

.message2_section {
    /* background-color: rgb(10, 45, 51); */
    background-image: linear-gradient(to right, rgb(24, 1, 7) , rgb(39, 3, 44));
    height: 800px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center; 
}
.message2_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.message2_first_line {
    font-family: 'Prata', serif;
    color: rgb(255, 255, 255);
    font-size: 40px;
    line-height: 1.2;
    text-align: center;
}
.message2_second_line {
    font-family: 'Krona One', sans-serif;
    color: rgba(230, 213, 67, 1);
    font-size: 84px;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 6px;
}
 .message2_third_line {
    font-family: 'Prata', serif;line-height: .8;
    color: white;
    font-size: 40px;
    line-height: .7;
    text-align: center;
 }

 @media screen and (max-width: 767.5px) and (min-width: 480px){
    .message2_section {
        height: 560px;
    }
    .message2_first_line {
        font-size: 30px;
        padding-top: 30px;
        letter-spacing: 1.5px;
        word-spacing: 8px;
    }    
    .message2_second_line {
        font-size: 50px;
        letter-spacing: .6px;
    }
     .message2_third_line {
        font-size: 30px;
        padding-bottom: 30px;
        letter-spacing: 1.5px;
        word-spacing: 3px;
     }    
}
@media screen and (max-width: 480px) and (min-width: 319px) {
    .message2_section {
        height: 480px;
    }    
    .message2_first_line {
        font-size: 20px;
        padding-top: 30px;
        letter-spacing: 1.5px;
    }    
    .message2_second_line {
        font-size: 30px;
    }
     .message2_third_line {
        font-size: 20px;
        padding-bottom: 30px;
     }
}