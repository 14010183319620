@import url('https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');

body {
    margin: 0;
}
.about_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    height: 100vh;
    width: 100vw;
}
.about_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    height: 100vh;
    width: 100vw;
    min-height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;    
}
.about_section_content_container {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
    width: 80vw;
    max-width: 1200px;
    background-color: rgba(0, 0, 0, .6);
    margin: 0 auto;
}
.about_headline {
    font-family: 'Krona One', sans-serif;
    color: rgba(230, 213, 67, 1);
    font-size: 120px;
    line-height: .9;
    margin: 48px 48px 24px;
    letter-spacing: 0.10em;
}
.about_content {
    font-family: 'Raleway', sans-serif;
    color: white;
    font-size: 18px;
    max-width: 850px;
    margin: 0 68px 24px;
    letter-spacing: .1em;
    line-height: 1.5;
}
.about_btn_space {
    margin: 24px 0 48px;
}
.about_button {
    font-family: 'Raleway', sans-serif;
    background-color: transparent;
    border-radius: 8px;
    border: 1px rgb(255,255,255) solid;
    color: rgb(255,255,255);
    font-size: 14px;
    /* font-weight: 600; */
    transition: all 0.3s;
    margin-bottom: 50px;
    padding: 8px 16px;
    letter-spacing: .1em;
    line-height: 2;
}
.about_button:hover {
    background:rgb(255,255,255);
    color: rgb(0,0,0); 
    cursor: pointer;
}

/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .about_section {
        height: 95vh;
    }
    .about_headline {
        font-size: 84px;
        line-height: 1;
        padding: 24px 24px 12px;
        letter-spacing: 0.10em;
    }
    .about_content {
        color: white;
        font-size: 18px;
        padding: 0 32px;
    }
}
/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px) {
    .about_section {
        height: 90vh;
    }
    .about_headline {
        font-size: 78px;
        line-height: 1;
        padding: 24px 24px 12px;
        letter-spacing: 0.10em;
    }
    .about_content {
        font-size: 18px;
        max-width: 900px;
        padding: 0 32px;
    }
}
/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 575.5px) {
    .about_section {
        height: 83vh;
    }
    .about_headline {
        font-size: 50px;
        line-height: 1;
        padding: 24px 48px 0px 48px;
        letter-spacing: 0.10em;
    }
    .about_content {
        font-size: 14px;
        max-width: 900px;
        padding: 0 32px;
    }
   
}
/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px) {
    .about_section {
        height: 100vh;
        width: 100vw;
    }
    .about_section_content_container {
        width: 100vw;
        height: 100vh;
    }
    .about_section_background {
        background-attachment: none;
        }
    .about_headline {
        font-size: 46px;
        line-height: 1;
        padding: 0;
        letter-spacing: 0.10em;
    }
    .about_content {
        font-size: 12px;
        padding: 0;
        line-height: 1.3;
        margin: 0 56px;
    }
    .about_button {
        font-size: 11px;
    }
}

