.photogallery_section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    background-color: rgb(255, 255, 255);
}

.photogallery_header {
    font-family: 'Kanit', sans-serif;
    color: black;
    font-size: 90px;
    margin: 0 auto;
    padding: 40px 40px 0;
    /* position: relative; */
}

.photogallery_secondline {
    font-family: 'Kanit', sans-serif;
    color: black;
    font-size: 20px;
    margin: 0 auto;
    padding: 0 0 10px;
    /* position: relative; */
}

/* the fluidity of the container (width) must be controlled by media query, it can't be automatic
because the column snake will continue on past the width of the right side of the screen if the 
height is too short  */

.photogallery_container {
    display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
    align-content: center;
    /* limits height of browser's viewable frame = 160% */
    height: 160vh;
    /* width of the container was based on where last column ended up */
    width: 100vh;
    margin: 0 20px;    
}

.photo_box {
    margin: 3px 6px;
}

.photo_image {
    /* object-fit: cover; */
    /* this controls the width of the columns */
    max-width: 400px;
}

@media screen and (max-width: 1665px) and (min-width: 319px){
    .photogallery_section {
        background-color: rgb(255, 255, 255);
    }
}

