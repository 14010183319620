@import url('https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');

.contact_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    height: 100vh;
    width: 100vw;
}
.contact_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    height: 100vh;
    width: 100vw;
    min-height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed; 
}
.contact_section_content_container {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
    width: 80vw;
    max-width: 1200px;
    background-color: rgba(0, 0, 0, .6);
    margin: 0 auto;
}
.contact_headline {
    font-family: 'Krona One', sans-serif;
    color: rgba(230, 213, 67, 1);
    font-size: 110px;
    line-height: .9;
    margin: 48px 48px 24px;
    letter-spacing: 0;
    word-spacing: 20px;
}
.contact_content {
    font-family: 'Raleway', sans-serif;
    color: white;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 1px;
    max-width: 900px;
    margin: 0 66px 24px;
}
.contact_button {
    font-family: 'Raleway', sans-serif;
    background-color: transparent;
    border-radius: 8px;
    border: 1px white solid;
    color: white;
    font-size: 16px;
    transition: all 0.3s;
    margin-bottom: 50px;
    padding: 8px 16px;
}
.contact_button:hover { 
    background:rgb(255,255,255);
    color: rgb(0,0,0); 
    cursor: pointer;
}
a {
    color: white;
    text-decoration: none;
}
p {
    margin-top: 0;
}

/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1300px) and (min-width: 992px) {
    .contact_section_content_container {
        background-color: rgba(0, 0, 0, .5);
    }    
    .contact_headline {
        font-size: 72px;
        line-height: .9;
        margin: 48px auto;
        word-spacing: 20px;
    }
    .contact_content {
        font-size: 14px;
        margin: 0 auto;
    } 
    .contact_button {
        width: 180px;
        font-size: 14px;
        padding: 10px;
    }
}

/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px){   
    .contact_section_content_container {
        background-color: rgba(0, 0, 0, .5);
    }    
    .contact_headline {
        font-size: 50px;
        line-height: 1;
        margin: 48px;
    }
    .contact_content {
        font-size: 12px;
        line-height: 1.4;
        margin: 0 60px;
    }  
    .contact_button {
        width: 160px;
        font-size: 14px;
        margin: 0 0 60px;
        padding: 10px;
    }  
}

/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 576px) {
    .contact_section_content_container {
        background-color: rgba(0, 0, 0, .6);
        margin: 10% auto;
        width: 90%;
        padding: 0;        
    }   
    .contact_headline {
        font-size: 32px;
        line-height: .7;
        margin-left: 32px;
        margin-top: 24px;
    }
    .contact_content {
        font-size: 11px;
        margin-left: 32px;
    }
    .contact_button {
        width: 30vw;
        font-size: 14px;
        padding: 10px;   
    }
    .contact_button:hover { 
        background:rgb(37, 214, 52); 
    }
}

/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px) {
    .contact_section_content_container {
        background-color: rgba(0, 0, 0, .6);
        border-radius: 0px;
        width: 100vw;
        height: 100vh;
        padding: 0; 
        margin: 0;       
    }
    .contact_headline {
        font-size: 24px;
        line-height: .7;
        padding: 0;
        margin: 24px;
        letter-spacing: .2px;
        text-shadow: 2px 2px black;
        word-spacing: 5px;
    }
    .contact_content {
        font-size: 14px;
        line-height: 1.3;
        padding: 12px 24px 0;
        margin: 0;
    }
    .contact_button {
        width: 40vw;
        font-size: 12px;
        padding: 8px;
        margin: 24px 0 48px;
        border-radius: 8px;
    }
}

