@import url('https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');

.brand_page {    
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-self: stretch;
    height: 100vh;
    width: 100vw;
}
.brand_page_background {
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.brand_pg_img {
    width: 1200px; 
    max-height: 800px;   
}
.brand_page_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    align-content: stretch;
    width: 100vw;
    height: 100vh;
    margin: 25% auto;
}
.brand_headline {
    font-family: 'Krona One', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 90px;
    line-height: 1;
    width: 100%;
    /* text-align: left; */
    padding: 0 0 50px 0;
    text-shadow: 3px 3px black;
}
.brand_content {
    font-family: 'Raleway', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 18px;
    width: 50vw;;
    padding: 80px;
    background-color: rgba(0, 0, 0, 0.6);
}
.brand_text {
    padding-bottom: 20px;
}
.brand_home_button {
    font-family: 'Raleway', sans-serif;
    background-color: transparent;
    border-radius: 8px;
    border: 1px rgb(255,255,255) solid;
    color: rgb(255,255,255);
    font-size: 16px;
    /* font-weight: 600; */
    transition: all 0.3s;
    margin-bottom: 50px;
   
    padding: 8px 16px;
    line-height: 3;
}
.brand_home_button:hover {
    background:rgb(255,255,255);
    color: rgb(0,0,0); 
    cursor: pointer;
}
.footer_section {
    width: 100%;
}

/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px) {
    .brand_page {    
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content:flex-start;
        align-items: center;
        align-self: center;
    }
    .brand_page_container {
        margin: 5% auto;
        justify-content:flex-start;        
        height: 120vh;
        width: 90vw;
        padding: 0 0 300px;
    }    
    .brand_headline {      
        font-size: 42px;
        line-height: 1.2;
        width: 70vw;
        text-shadow: 3px 3px black;
        padding: 12px;
    }
    .brand_content {
        font-size: 14px;
        width: 80vw;
        padding: 12px 12px 24px; 
        line-height: 1.2;     
    }
    .brand_home_button {
        font-size: 12px;
    }
}