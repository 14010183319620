@import url(https://fonts.googleapis.com/css?family=Kaushan+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Prata&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Prata&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway|Yesteryear&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Prata&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Prata&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Prata&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Monoton&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poiret+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Prata&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);

body {
    margin: 0;
}
.main_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    height: 100vh;
    width: 100vw;
}
.main_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;  
}
.main_section_content_container {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
    height: 80vh;
    max-width: 1200px;
    margin: 0 auto; 
}
.main_logo_space {
    margin: 0 auto;
}
.main_logo_img {
    height: 600px;
}
.main_first_line {
    font-family: 'Krona One', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 84px;
    text-shadow: 2px 2px black;
    line-height: 1.1;
    letter-spacing: 8px;
    text-align: center;
    margin: 24px 48px 12px;
}
.main_second_line {
    font-family: 'Krona One', sans-serif;
    color: rgba(230, 213, 67, 1);
    font-size: 84px;  
    text-shadow: 2px 2px black;      
    line-height: 1.1;
    text-align: center;
}
/* -------------------- LAPTOP ------------- */
@media screen and (max-width: 1500px) and (min-width: 1200.5px) {
    .main_section_content_container {
        width: 100vw;
        height: none;
        margin: 0 auto;
        padding: 0;
    }
    .main_logo_img {
        height: 400px;
        padding: 40px 0 0;
    }
    .main_first_line {
        font-size: 64px;
        line-height: 1.1;
        padding: 0 12px 0px;
        letter-spacing: 6px;
    }
    .main_second_line {
        font-size: 76px;        
        line-height: .8;
        padding: 6px 12px;
        text-shadow: 2px 2px black;
    }  
}
/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .main_section_content_container {
        width: 80vw;
        height: none;
        margin: 13vh auto;
    }
    .main_logo_img {
        height: 200px;
        padding: 40px 0 0;
    }
    .main_first_line {
        font-size: 55px;
        line-height: 1.1;
        padding: 12px 12px 0px;
        letter-spacing: 5px;
    }
    .main_second_line {
        font-size: 68px;        
        line-height: .8;
        padding: 6px 12px 48px;
        text-shadow: 2px 2px black;
    }  
}

/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991.5px) and (min-width: 768px){    
    .main_section_content_container {
        width: 80vw;
        height: none;
        margin: 13vh auto;
    }
    .main_logo_img {
        height: 150px;
        padding: 80px 0 0;
    }
    .main_first_line {
        font-size: 42px;
        line-height: 1.1;
        padding: 12px 12px 0px;
        letter-spacing: 5px;
    }
    .main_second_line {
        font-size: 58px;        
        line-height: .9;
        padding: 6px 12px 48px;
        text-shadow: 2px 2px black;
    }  

}
/* -------------------- LARGE PHONE------------- */
@media screen and (max-width: 767.5px) and (min-width: 480px){
    .main_section_content_container {
        width: 80vw;
        height: none;
        margin: 15vh auto;
    }
    .main_logo_img {
        height: 100px;
        padding: 40px 0 0;
    }
    .main_first_line {
        font-size: 30px;
        line-height: 1.1;
        padding: 12px 12px 0px;
        letter-spacing: 5px;
    }
    .main_second_line {
        font-size: 30px;        
        line-height: 1;
        padding: 6px 12px 48px;
        text-shadow: 2px 2px black;
    }    
}
/* -------------------- SMALL PHONES ------------- */
@media screen and (max-width: 479.5px) and (min-width: 319px){  
    
    .main_section_content_container {
        width: 80vw;
        height: none;
        margin: 15vh auto;
    }
    .main_logo_space {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
    }
    .main_logo_img {
        height: 90px; 
    }
    .main_first_line {
        font-family: 'Krona One', sans-serif;
        color: rgb(255, 255, 255);
        font-size: 24px;
        line-height: 1.1;
        letter-spacing: 2px;
        margin: 0 auto;
        padding: 8px 8px 0px;
    }
    .main_second_line {
        font-family: 'Krona One', sans-serif;
        color: rgba(230, 213, 67, 1);
        font-size: 24px;        
        line-height: 1.1;
        text-align: center;
        text-shadow: 2px 2px black;
    }
}



body {
    margin: 0;
}
.about_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    height: 100vh;
    width: 100vw;
}
.about_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    height: 100vh;
    width: 100vw;
    min-height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;    
}
.about_section_content_container {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
    width: 80vw;
    max-width: 1200px;
    background-color: rgba(0, 0, 0, .6);
    margin: 0 auto;
}
.about_headline {
    font-family: 'Krona One', sans-serif;
    color: rgba(230, 213, 67, 1);
    font-size: 120px;
    line-height: .9;
    margin: 48px 48px 24px;
    letter-spacing: 0.10em;
}
.about_content {
    font-family: 'Raleway', sans-serif;
    color: white;
    font-size: 18px;
    max-width: 850px;
    margin: 0 68px 24px;
    letter-spacing: .1em;
    line-height: 1.5;
}
.about_btn_space {
    margin: 24px 0 48px;
}
.about_button {
    font-family: 'Raleway', sans-serif;
    background-color: transparent;
    border-radius: 8px;
    border: 1px rgb(255,255,255) solid;
    color: rgb(255,255,255);
    font-size: 14px;
    /* font-weight: 600; */
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin-bottom: 50px;
    padding: 8px 16px;
    letter-spacing: .1em;
    line-height: 2;
}
.about_button:hover {
    background:rgb(255,255,255);
    color: rgb(0,0,0); 
    cursor: pointer;
}

/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .about_section {
        height: 95vh;
    }
    .about_headline {
        font-size: 84px;
        line-height: 1;
        padding: 24px 24px 12px;
        letter-spacing: 0.10em;
    }
    .about_content {
        color: white;
        font-size: 18px;
        padding: 0 32px;
    }
}
/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px) {
    .about_section {
        height: 90vh;
    }
    .about_headline {
        font-size: 78px;
        line-height: 1;
        padding: 24px 24px 12px;
        letter-spacing: 0.10em;
    }
    .about_content {
        font-size: 18px;
        max-width: 900px;
        padding: 0 32px;
    }
}
/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 575.5px) {
    .about_section {
        height: 83vh;
    }
    .about_headline {
        font-size: 50px;
        line-height: 1;
        padding: 24px 48px 0px 48px;
        letter-spacing: 0.10em;
    }
    .about_content {
        font-size: 14px;
        max-width: 900px;
        padding: 0 32px;
    }
   
}
/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px) {
    .about_section {
        height: 100vh;
        width: 100vw;
    }
    .about_section_content_container {
        width: 100vw;
        height: 100vh;
    }
    .about_section_background {
        background-attachment: none;
        }
    .about_headline {
        font-size: 46px;
        line-height: 1;
        padding: 0;
        letter-spacing: 0.10em;
    }
    .about_content {
        font-size: 12px;
        padding: 0;
        line-height: 1.3;
        margin: 0 56px;
    }
    .about_button {
        font-size: 11px;
    }
}


/* .section {    
    display: flex;
    margin: 0 auto;
}

.section_background {
    height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.section_content_container {
   padding-top: 80px;
   padding-left: 80px;
   padding-right: 80px;
}

.section_title {
    color: white;
    font-size: 56px;
    margin: 0 auto;
    padding: 80px 80px 10px;
}

.section_content {
    color: white;
    font-size: 24px;
    margin: 0 auto;
    padding: 0px 80px 10px;
}

.button {
    background-color: blueviolet;
    margin: 10px 80px 10px;
    padding: 12px 16px;
    border-radius: 8px;
    border: 3px white solid;
    color: white;
} */
.contact_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    height: 100vh;
    width: 100vw;
}
.contact_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    height: 100vh;
    width: 100vw;
    min-height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed; 
}
.contact_section_content_container {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
    width: 80vw;
    max-width: 1200px;
    background-color: rgba(0, 0, 0, .6);
    margin: 0 auto;
}
.contact_headline {
    font-family: 'Krona One', sans-serif;
    color: rgba(230, 213, 67, 1);
    font-size: 110px;
    line-height: .9;
    margin: 48px 48px 24px;
    letter-spacing: 0;
    word-spacing: 20px;
}
.contact_content {
    font-family: 'Raleway', sans-serif;
    color: white;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 1px;
    max-width: 900px;
    margin: 0 66px 24px;
}
.contact_button {
    font-family: 'Raleway', sans-serif;
    background-color: transparent;
    border-radius: 8px;
    border: 1px white solid;
    color: white;
    font-size: 16px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin-bottom: 50px;
    padding: 8px 16px;
}
.contact_button:hover { 
    background:rgb(255,255,255);
    color: rgb(0,0,0); 
    cursor: pointer;
}
a {
    color: white;
    text-decoration: none;
}
p {
    margin-top: 0;
}

/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1300px) and (min-width: 992px) {
    .contact_section_content_container {
        background-color: rgba(0, 0, 0, .5);
    }    
    .contact_headline {
        font-size: 72px;
        line-height: .9;
        margin: 48px auto;
        word-spacing: 20px;
    }
    .contact_content {
        font-size: 14px;
        margin: 0 auto;
    } 
    .contact_button {
        width: 180px;
        font-size: 14px;
        padding: 10px;
    }
}

/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px){   
    .contact_section_content_container {
        background-color: rgba(0, 0, 0, .5);
    }    
    .contact_headline {
        font-size: 50px;
        line-height: 1;
        margin: 48px;
    }
    .contact_content {
        font-size: 12px;
        line-height: 1.4;
        margin: 0 60px;
    }  
    .contact_button {
        width: 160px;
        font-size: 14px;
        margin: 0 0 60px;
        padding: 10px;
    }  
}

/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 576px) {
    .contact_section_content_container {
        background-color: rgba(0, 0, 0, .6);
        margin: 10% auto;
        width: 90%;
        padding: 0;        
    }   
    .contact_headline {
        font-size: 32px;
        line-height: .7;
        margin-left: 32px;
        margin-top: 24px;
    }
    .contact_content {
        font-size: 11px;
        margin-left: 32px;
    }
    .contact_button {
        width: 30vw;
        font-size: 14px;
        padding: 10px;   
    }
    .contact_button:hover { 
        background:rgb(37, 214, 52); 
    }
}

/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px) {
    .contact_section_content_container {
        background-color: rgba(0, 0, 0, .6);
        border-radius: 0px;
        width: 100vw;
        height: 100vh;
        padding: 0; 
        margin: 0;       
    }
    .contact_headline {
        font-size: 24px;
        line-height: .7;
        padding: 0;
        margin: 24px;
        letter-spacing: .2px;
        text-shadow: 2px 2px black;
        word-spacing: 5px;
    }
    .contact_content {
        font-size: 14px;
        line-height: 1.3;
        padding: 12px 24px 0;
        margin: 0;
    }
    .contact_button {
        width: 40vw;
        font-size: 12px;
        padding: 8px;
        margin: 24px 0 48px;
        border-radius: 8px;
    }
}


.services_section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    align-content: flex-start;
}
.services_section_background {
    width: 100vw;
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
}
.services_header {
    font-family: 'Kanit', sans-serif;
    color:white;
    font-size: 90px;
    text-align: center;
    padding: 24px;
}
.services_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
    width: 80vw;
    max-width: 1500px;
    margin: 10% auto;
    padding: 50px 0 50px;
}
.services_page_title {
    font-family: 'Krona One', sans-serif;
    line-height: 1;
    text-align: center;
    color: rgba(230, 213, 67, 1);  
    font-size: 72px;
    padding-top: 44px;
    width: 100vw;
    text-shadow: 2px 2px black;
}
.services_page_subtitle {
    font-family: 'Raleway', sans-serif;
    color: rgb(255, 255, 255);
    line-height: 1;
    text-align: center;
    font-size: 26px;
    padding-top: 10px;
    padding-bottom: 44px;
    width: 100vw;
    text-shadow: 2px 2px black;
}
#highlight {
    color: rgb(230, 213, 67);
}
.services_box {
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 350px;
    min-height: 350px;
    color: white;
    border-radius: 6px;
    padding: 12px;
    margin: 12px 10px;
    background-color: rgba(0, 0, 0, 0.7);
}
.service_box_title {
    font-family: 'Krona One', sans-serif;
    font-size: 22px;
    color: rgb(230, 213, 67);
    padding: 0 8px 12px;
    line-height: 1.1;
    letter-spacing: 1.5px;
    text-align: center;
}
.icon_image {
    height: 140px;
    -webkit-filter: white;
            filter: white;
    margin: 2% auto;
}
.service_box_content {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: white;
    padding: 0px 8px 14px;
    text-align: center;
    justify-content: left;
    max-width: 320px;
}
.service_box_button {
    font-family: 'Raleway', sans-serif;
    background-color: transparent;
    border-radius: 8px;
    border: 1px white solid;
    color: white;
    font-size: 16px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    padding: 8px 16px;
    line-height: 4;
}
.service_box_button:hover {
    background:rgb(255,255,255);
    color: rgb(0,0,0); 
    cursor: pointer;
}
/* ------------------- LARGE MOBILE ---------------------- */
@media screen and (max-width: 767.5px) and (min-width: 480px) {
    .services_page_title { 
        font-size: 36px;      
    }
    .services_page_subtitle {
        font-size: 18px;      
    }  
    .services_container {
        width: 80vw;  
        margin: 0 auto;
        padding: 0 0 200px;   
    }
    .services_box {
        width: 80vw;
        color: white;
        border-radius: 6px;
        padding: 6px;
        margin: 6px;
        background-color: rgba(0, 0, 0, 0.6);
    }
    .service_box_title {
        font-family: 'Kanit', sans-serif;
        font-size: 22px;
        line-height: .9;
        color: rgb(230, 213, 67);
    }
    .service_box_content {
        line-height: 1.2;
        font-size: 12px;
        color: white;
        padding: 8px 8px;
    }
    .icon_image {
        height: 100px;
        -webkit-filter: white;
                filter: white;
        padding: 8px;
    } 
}
/* ------------------- SMALL MOBILE ---------------------- */
@media screen and (max-width: 480px) and (min-width: 319px){
   
    .services_page_title { 
        font-size: 36px;      
    }
    .services_page_subtitle {
        font-size: 16px;      
    }
    .services_container {
        width: 80vw;  
        margin: 0 auto;
        padding: 0 0 200px;   
    }
    .services_box {
        width: 80vw;
        color: white;
        border-radius: 6px;
        padding: 6px;
        margin: 6px;
        background-color: rgba(0, 0, 0, 0.6);
    }
    .service_box_title {
        font-family: 'Kanit', sans-serif;
        font-size: 22px;
        line-height: .9;
        color: rgb(230, 213, 67);
    }
    .service_box_content {
        line-height: 1.2;
        font-size: 12px;
        color: white;
        padding: 8px 8px;
    }
    .icon_image {
        height: 100px;
        -webkit-filter: white;
                filter: white;
        padding: 8px;
    }
}
.message3_section {
    /* background-color: rgba(0, 0, 0); */
    /* background-image: linear-gradient(to right, rgb(5, 4, 46), rgb(4, 32, 58)); */
    background-image: -webkit-gradient(linear, left top, right top, from(rgb(24, 1, 7)) , to(rgb(39, 3, 44)));
    background-image: linear-gradient(to right, rgb(24, 1, 7) , rgb(39, 3, 44));
    height: 800px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center; 
}

.message3_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center; 
}

.message3_first_line {
    font-family: 'Prata', serif;
    color: rgb(255, 255, 255);
    font-size: 40px;
    line-height: 1.2;
    text-align: center;
}

.message3_second_line {
    font-family: 'Krona One', sans-serif;
    color: rgba(230, 213, 67, 1);
    font-size: 80px;
    line-height: 1.1;
    text-align: center;
    margin-bottom: 6px;
}
 .message3_third_line {
    font-family: 'Prata', serif;
    line-height: .8;
    color: white;
    font-size: 40px;
    line-height: .5;
    text-align: center;
 }
 @media screen and (max-width: 767.5px) and (min-width: 480px){
    .message3_section {
        height: 560px;
    }

    .message3_first_line {
        font-size: 30px;
        padding-top: 30px;
        letter-spacing: 1.5px;
        word-spacing: 8px;
    }
    
    .message3_second_line {
        font-size: 50px;
        letter-spacing: .8px;
    }
     .message3_third_line {
        font-size: 30px;
        padding-bottom: 30px;
        letter-spacing: 1.5px;
        word-spacing: 8px;
     }
    
}
@media screen and (max-width: 479.5px) and (min-width: 319px) {
    .message3_section {
        height: 480px;
    }
    .message3_first_line {
        font-size: 20px;
        padding-top: 30px;
        letter-spacing: 1px;
    }
    
    .message3_second_line {
        font-size: 30px;
        letter-spacing: 1px;
    }
     .message3_third_line {
        font-size: 20px;
        padding-bottom: 30px;
        letter-spacing: .7px;
     }
}
.portfolio_section {
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    /* height: 100vh; */
    width: 100vw;
    background-color: rgb(255, 255, 255);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
}
.portfolio_section_title {
    font-family: 'Krona One', sans-serif;
    color: rgba(230, 213, 67, 1);
    text-align: center;
    font-size: 72px;
    line-height: 1;
    padding-top: 24px;
    width: 100vw;
    text-shadow: rgba(0, 0, 0, 0.7) 3px 3px;
}
.portfolio_section_subtitle {
    font-family: 'Raleway', sans-serif;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 26px;
    line-height: 1;
    width: 100vw;
    text-shadow: rgba(0, 0, 0, 0.7) 2px 2px;
}
.portfolio_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-width: 1400px;
    padding: 10% 0 10%;
}
.portfolio_box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 6px;
    max-width: 550px;
    height: 500px;
    padding: 12px;
    margin: 30px 25px;
}
.portfolio_box_title {
    font-family: 'Krona One', sans-serif;
    color: rgb(230, 213, 67);
    font-size: 22px;
    line-height: 1.1;
    letter-spacing: 1.5px;
    margin: 12px 24px 12px 24px;
}
.portfolio_box_content {
    font-family: 'Raleway', sans-serif;
    color: white;
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 1.2px;
    margin: 6px 24px 12px;
}
.portfolio_box_image {
    margin: 24px;
    max-width: 480px;
    border: 8px white solid;
    border-radius: 8px;
}

@media screen and (max-width: 480px) and (min-width: 319px) {
    .portfolio_section_title {
        font-size: 36px;      
    }
    .portfolio_section_subtitle {
        font-size: 22px;
    }
    .portfolio_box {
        padding: 8px;
        margin: 10%;
        max-height: 384px;
        width: 80vw;
    }
    .portfolio_box_title {
        font-size: 20px;
        line-height: 1;
        margin: 6px;
    }
    .portfolio_box_content {
        font-size: 14px;
        line-height: 1;
        margin: 6px;
    }
    .portfolio_box_image {
        margin: 6px;
        width: 70vw;
    }
}

.footer_section {
    /* background-color: rgb(2, 17, 31); */
    background-color: rgb(5, 5, 5);
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    height: 100%;
    width: 100%;    
}

.footer_content {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: rgb(165, 158, 158);
    margin: 0 auto;
    padding: 2px 24px 2px;
}

.footer_gap {
    padding-left: 5px;
    padding-right: 5px;
}

.logo_img {
    height: 60px;
    margin: 24px 0 8px 0;
}

.back_to_top {    
    padding: 12px 0 24px; 
    margin: 0 auto; 
    cursor: pointer;;
}

.yellow {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: rgba(230, 213, 67, 1);
    text-decoration: none;
}

/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px) {
   
    .footer_content {
        text-align: center;
        padding: 12px 24px 12px;
    }
 
}
.nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    text-align: right;
    width: 100vw;
    background-image: -webkit-gradient(linear, left top, right top, from(rgb(18, 18, 18)), to(rgb(0, 0, 0)));
    background-image: linear-gradient(to right, rgb(18, 18, 18), rgb(0, 0, 0));
    color: white;
    padding-top: 8px;
    padding-right: 40px;
    padding-bottom: 6px;
    height: 26px;
    z-index: 1;
}

.nav-links {
    font-family: 'Raleway', sans-serif;
    font-size: .7em;
    list-style: none;
    padding: 5px 10px 10px 10px;
    margin-right: 18px;
}   
.nav-links-last {
    font-family: 'Raleway', sans-serif;
    font-size: .7em;
    list-style: none;
    padding: 5px 20px 10px 10px;
    margin-right: 18px;
}   

.nav-bar > .active {
    font-family: 'Raleway', sans-serif;
    color: peru;
    border-bottom: 2px solid white;    
}

.nav_logo {
    width: 130px;
    float: left;
    padding-left: 20px;
}

@media screen and (max-width: 600px) {
    .nav-bar {
        position: fixed;
        padding-right: 100vw;  
    }
    .nav-links {
        display: none;
        width: 100vw;
    }

    .nav_logo {
        width: 120px;
        float: left;
        padding: 0 12px;
    }
    
}


.message1_section {
    /* background-color: rgb(33, 8, 39); */
    background-image: -webkit-gradient(linear, left top, right top, from(rgb(18, 18, 18)), to(rgb(0, 0, 0)));
    background-image: linear-gradient(to right, rgb(18, 18, 18), rgb(0, 0, 0));
    height: 800px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center; 
}

.message1_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center; 
}

.message1_first_line {
    font-family: 'Prata', serif;
    color: rgb(255, 255, 255);
    font-size: 40px;
    line-height: 1.2;
    text-align: center;
}

.message1_second_line {
    font-family: 'Krona One', sans-serif;
    color: rgba(230, 213, 67, 1);
    font-size: 84px;
    line-height: 1.1;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 6px;
}
 .message1_third_line {
    font-family: 'Prata', serif;line-height: .8;
    color: white;
    font-size: 40px;
    line-height: .5;
    text-align: center;

 }

 @media screen and (max-width: 767.5px) and (min-width: 480px){
    .message1_section {
        height: 560px;
    }

    .message1_first_line {
        font-size: 28px;
        padding-top: 30px;
        letter-spacing: .7px;
    }
    
    .message1_second_line {
        font-size: 50px;
        letter-spacing: 8px;
    }
     .message1_third_line {
        font-size: 28px;
        padding-bottom: 30px;
        letter-spacing: 1.2px;
     }
    
}
@media screen and (max-width: 479.5px) and (min-width: 319px) {
    .message1_section {
        height: 560px;
    }

    .message1_first_line {
        font-size: 20px;
        padding-top: 30px;
    }
    
    .message1_second_line {
        font-size: 30px;
        letter-spacing: 8px;
    }
     .message1_third_line {
        font-size: 20px;
        padding-bottom: 30px;
     }
}

.message2_section {
    /* background-color: rgb(10, 45, 51); */
    background-image: -webkit-gradient(linear, left top, right top, from(rgb(24, 1, 7)) , to(rgb(39, 3, 44)));
    background-image: linear-gradient(to right, rgb(24, 1, 7) , rgb(39, 3, 44));
    height: 800px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center; 
}
.message2_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.message2_first_line {
    font-family: 'Prata', serif;
    color: rgb(255, 255, 255);
    font-size: 40px;
    line-height: 1.2;
    text-align: center;
}
.message2_second_line {
    font-family: 'Krona One', sans-serif;
    color: rgba(230, 213, 67, 1);
    font-size: 84px;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 6px;
}
 .message2_third_line {
    font-family: 'Prata', serif;line-height: .8;
    color: white;
    font-size: 40px;
    line-height: .7;
    text-align: center;
 }

 @media screen and (max-width: 767.5px) and (min-width: 480px){
    .message2_section {
        height: 560px;
    }
    .message2_first_line {
        font-size: 30px;
        padding-top: 30px;
        letter-spacing: 1.5px;
        word-spacing: 8px;
    }    
    .message2_second_line {
        font-size: 50px;
        letter-spacing: .6px;
    }
     .message2_third_line {
        font-size: 30px;
        padding-bottom: 30px;
        letter-spacing: 1.5px;
        word-spacing: 3px;
     }    
}
@media screen and (max-width: 480px) and (min-width: 319px) {
    .message2_section {
        height: 480px;
    }    
    .message2_first_line {
        font-size: 20px;
        padding-top: 30px;
        letter-spacing: 1.5px;
    }    
    .message2_second_line {
        font-size: 30px;
    }
     .message2_third_line {
        font-size: 20px;
        padding-bottom: 30px;
     }
}
.message4_section {
    /* background-color: rgb(18, 18, 18); */
    background-image: -webkit-gradient(linear, left top, right top, from(rgb(18, 18, 18)), to(rgb(0, 0, 0)));
    background-image: linear-gradient(to right, rgb(18, 18, 18), rgb(0, 0, 0));
    height: 800px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center; 
}
.message4_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center; 
}
.message4_first_line {
    font-family: 'Prata', serif;
    color: rgb(255, 255, 255);
    font-size: 40px;
    line-height: 1.2;
    text-align: center;
}
.message4_second_line {
    font-family: 'Krona One', sans-serif;
    color: rgba(230, 213, 67, 1);
    font-size: 80px;
    line-height: 1.1;
    text-align: center;
    margin-bottom: 6px;
}
 .message4_third_line {
    font-family: 'Prata', serif;
    line-height: .8;
    color: white;
    font-size: 40px;
    line-height: .5;
    text-align: center;
 }
 @media screen and (max-width: 767.5px) and (min-width: 480px){
    .message4_section {
        height: 560px;
    }
    .message4_first_line {
        font-size: 30px;
        padding-top: 30px;
        letter-spacing: 1.5px;
        word-spacing: 8px;
    }    
    .message4_second_line {
        font-size: 50px;
        letter-spacing: .8px;
    }
     .message4_third_line {
        font-size: 30px;
        padding-bottom: 30px;
        letter-spacing: 1.5px;
        word-spacing: 8px;
     }    
}
@media screen and (max-width: 480px) and (min-width: 319px) {
    .message4_section {
        height: 480px;
    }
    .message4_first_line {
        font-size: 20px;
        padding-top: 30px;
    }
    
    .message4_second_line {
        font-size: 30px;
    }
     .message4_third_line {
        font-size: 20px;
        padding-bottom: 30px;
        letter-spacing: 1px;
     }
}
body {
    background-color: rgb(18,18,18);
}

/* ------- TOP HEADER SECTION --------------------------------- */
.pg_grid_section {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
    height: 100vh;
    width: 100vw;
}
.pg_grid_header {
    font-family: 'Monoton', cursive;
    color: rgb(255,255,255);
    font-size: 84px;
    line-height: 1.2;
    letter-spacing: 24px;
    word-spacing: 30px;
    margin: 0 auto;
    padding: 40px 20px 20px;
    position: relative;
}
.pg_grid_secondline {
    font-family: 'Poiret One', cursive;
    color: rgb(255,255,255);
    font-size: 40px;
    letter-spacing: 1.4px;
    word-spacing: 4px;
    margin: 0 auto;
    padding: 0 10px 40px;
}

/* ------ BOTTOM PHOTO GALLERY QUOTE SECTION ----------------------------------- */
.pg_grid_homebtn_space {    
    display: flex;    
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
	align-items: center;
	align-content: center;
    height: 100vh;  
    margin: 10vh;  
}
.pg_grid_quote {
    font-family: 'Poiret One', cursive;
    letter-spacing: 4px;
    line-height: .9;
    color: rgb(167, 163, 163);
    font-size: 84px;
    text-align: center;
    margin: 48px 0 12px;
}
.pg_grid_quote_source {
    font-family: 'Raleway', sans-serif;
    font-size: 64px;
    font-style: normal;
    color: rgb(167, 163, 163);
    margin: 0 48px 48px;
}
.pg_grid_home_button {
    display: inline;
    font-family: 'Raleway', sans-serif;
    background-color: transparent;
    border-radius: 8px;
    border: 1px rgb(255,255,255) solid;
    color: rgb(255,255,255);
    font-size: 16px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin: 44px auto;
    padding: .7rem 1.5rem;
    line-height: 1;
}
.pg_grid_home_button:hover {
    background:rgb(255,255,255);
    color: rgb(0,0,0); 
    cursor: pointer;
}
.footer_style {
    width: 100%;
}

/* -------------- DESKTOP PHOTO GALLERY GRID CODE - (Min Width 1500px)------------------------------------- */
.pg_grid_container {
    display: grid;
    grid-template-columns: repeat(8,1fr);
    grid-auto-rows: 400px;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    justify-items: stretch;
    align-items: stretch;
    margin: 0 10px;
}
.pg_grid_box {
    width: 100%;
    height: 100%;
    /* overflow:hidden hides parts of images that cross over another */
    overflow: hidden;
    position: relative;
}
.pg_grid_image {
    width: 100%;
    height: 100%;
    /* object-fit:cover makes image auto size itself and fills in space */
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}
.pg_grid_image:hover {
    /* makes images pop bigger */
    -webkit-transform:scale(1.25);
            transform:scale(1.25);
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
}

/* ----------- GALLERY GRID SPANNING CONTROLS ---- DEFAULT DESKTOP SCREENS --------- */
.p-1 {
    grid-column: span 5;
    grid-row: span 1;
}
.p-2 {
    grid-column: span 1;
    grid-row: span 1;
}
.p-3 {
    grid-column: span 2;
    grid-row: span 2;
}
.p-4 {
    grid-column: span 3;
    grid-row: span 3;
}
.p-5 {
    grid-column: span 3;
    grid-row: span 1;
}
.p-6 {
    grid-column: span 5;
    grid-row: span 2;
}
.p-7 {
    grid-column: span 2;
    grid-row: span 1;
}
.p-8 {
    grid-column: span 6;
    grid-row: span 2;
}
.p-9 {
    grid-column: span 2;
    grid-row: span 1;
}
.p-10 {
    grid-column: span 5;
    grid-row: span 2;
}
.p-11 {
    grid-column: span 1;
    grid-row: span 1;
}
.p-12 {
    grid-column: span 2;
    grid-row: span 1;
}
.p-13 {
    grid-column: span 3;
    grid-row: span 4;
}
.p-14 {
    grid-column: span 3;
    grid-row: span 1;
}
.p-15 {
    grid-column: span 2;
    grid-row: span 1;
}
.p-16 {
    grid-column: span 5;
    grid-row: span 2;
}

.p-18 {
    grid-column: span 8;
    grid-row: span 2;
}
.p-19 {
    grid-column: span 3;
    grid-row: span 1;
}
.p-20 {
    grid-column: span 5;
    grid-row: span 2;
}
.p-21 {
    grid-column: span 3;
    grid-row: span 3;
}
.p-22 {
    grid-column: span 2;
    grid-row: span 1;
}
.p-23 {
    grid-column: span 3;
    grid-row: span 2;
}
.p-24 {
    grid-column: span 2;
    grid-row: span 1;
}
.p-25 {
    grid-column: span 6;
    grid-row: span 2;
}
.p-26 {
    grid-column: span 2;
    grid-row: span 2;
}
.p-27 {
    grid-column: span 6; 
    grid-row: span 2;
}
.p-28 {
    grid-column: span 2;
    grid-row: span 3;
}
.p-29 {
    grid-column: span 4;
    grid-row: span 2;
}
.p-30 {
    grid-column: span 2;
    grid-row: span 1;
}
.p-31 {
    grid-column: span 4;
    grid-row: span 1;
}
.p-32 {
    grid-column: span 8;
    grid-row: span 3;
}





/* --------------- MEDIA QUERIES ----------------------------------------------------- */
/* --------------- TABLET MEDIA QUERIES---------------------------- */
@media screen and (max-width: 1500px) and (min-width: 800.5px){      
    .pg_grid_header {
        font-size: 64px;
        line-height: 1;
        letter-spacing: 12px;
        word-spacing: 20px;
        margin-bottom: 20px;
    }
    .pg_grid_container {
        grid-template-columns: repeat(3,1fr);
        grid-auto-rows: 400px;
        
    }
    .pg_grid_homebtn_space {    
        height: 100%;
        padding: 14px;
        margin: 5% auto;
    }
    .pg_grid_quote {
        font-size: 36px;
        line-height: 1.2;
    }
    .pg_grid_quote_source {
        font-size: 20px;
        font-style: normal;
        color: rgb(167, 163, 163);
        margin: 12px 0 0 0;
    }
    .pg_grid_home_button {
        margin: 44px auto;
        padding: .7rem 1.5rem;     
    }
    /* ---------- TABLET SPANNING WIDTH CONTROLS ------- */
    .p-1 {
        grid-column: span 3;
        grid-row: span 1;
    }
    .p-2 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-3 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-4 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-5 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-6 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-7 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-8 {
        grid-column: span 3;
        grid-row: span 1;
    }
    .p-9 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-10 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-11 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-12 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-13 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-14 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-15 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-16 {
        grid-column: span 3;
        grid-row: span 1;
    }
    .p-17 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-18 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-19 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-20 {
        grid-column: span 3;
        grid-row: span 1;
    }
    .p-21 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-22 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-23 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-24 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-25 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-26 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-27 {
        grid-column: span 2; 
        grid-row: span 2;
    }
    .p-28 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-29 {
        grid-column: span 3;
        grid-row: span 1;
    }
    .p-30 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-31 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-32 {
        grid-column: span 3;
        grid-row: span 1;
    }
  
}

/* ---------- LARGE PHONE MEDIA QUERIES--------------------------------- */
@media screen and (max-width: 800px) and (min-width: 575.5px){  
    .pg_grid_header {
        font-size: 36px;
        line-height: 1;
        letter-spacing: 12px;
        word-spacing: 20px;
        margin-bottom: 20px;
    }
    .pg_grid_container {
        grid-template-columns: repeat(2,1fr);
        grid-auto-rows: 400px;        
    }
    .pg_grid_homebtn_space {    
        height: 100%;
        padding: 14px;
        margin: 5% auto;
    }
    .pg_grid_quote {
        font-size: 24px;
        line-height: 1.2;
    }
    .pg_grid_quote_source {
        font-size: 18px;       
    }
    .pg_grid_home_button {
        margin: 44px auto;
        padding: .7rem 1.5rem;       
    }
  /* ---------- LARGE PHONE SPANNING WIDTH CONTROLS ------- */
    .p-1 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-2 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-3 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-4 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-5 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-6 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-7 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-8 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-9 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-10 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-11 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-12 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-13 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-14 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-15 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-16 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-17 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-18 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-19 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-20 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-21 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-22 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-23 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-24 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-25 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-26 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-27 {
        grid-column: span 1; 
        grid-row: span 1;
    }
    .p-28 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-29 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-30 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-31 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-32 {
        grid-column: span 2;
        grid-row: span 2;
    }
       
}

/* ----------- SMALL PHONE MEDIA QUERIES------------------------------------- */
@media screen and (max-width: 575px) and (min-width: 319px){  
    .pg_grid_header {
        font-size: 32px;
        line-height: 1.2;
        letter-spacing: 12px;
        text-align: center;
    }
    .pg_grid_secondline {
        font-size: 14px;
        line-height: 1;
        letter-spacing: 1.2px;
        margin: 0 auto;
        padding: 0 10px 24px;
    }
    .pg_grid_container {
        grid-template-columns: 1fr;
        grid-auto-rows: 400px;        
    }
    .pg_grid_homebtn_space {    
        height: 100%;
        padding: 24px;
        margin: 20px;
    }
    .pg_grid_quote {
        font-family: 'Raleway', sans-serif;
        font-size: 24px;
        line-height: 1.2;
    }
    .pg_grid_quote_source {
        font-size: 18px;      
    }
    .pg_grid_home_button {
        margin: 12px auto;
        padding: .5rem 1.5rem;  
    }   
 
  /* ---------- SMALL PHONE SPANNING WIDTH CONTROLS ------- */
    .p-1 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-2 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-3 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-4 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-5 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-6 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-7 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-8 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-9 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-10 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-11 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-12 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-13 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-14 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-15 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-16 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-17 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-18 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-19 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-20 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-21 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-22 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-23 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-24 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-25 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-26 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-27 {
        grid-column: span 1; 
        grid-row: span 1;
    }
    .p-28 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-29 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-30 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-31 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-32 {
        grid-column: span 1;
        grid-row: span 1;
    }
        
}
.photogallery_section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    background-color: rgb(255, 255, 255);
}

.photogallery_header {
    font-family: 'Kanit', sans-serif;
    color: black;
    font-size: 90px;
    margin: 0 auto;
    padding: 40px 40px 0;
    /* position: relative; */
}

.photogallery_secondline {
    font-family: 'Kanit', sans-serif;
    color: black;
    font-size: 20px;
    margin: 0 auto;
    padding: 0 0 10px;
    /* position: relative; */
}

/* the fluidity of the container (width) must be controlled by media query, it can't be automatic
because the column snake will continue on past the width of the right side of the screen if the 
height is too short  */

.photogallery_container {
    display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
    align-content: center;
    /* limits height of browser's viewable frame = 160% */
    height: 160vh;
    /* width of the container was based on where last column ended up */
    width: 100vh;
    margin: 0 20px;    
}

.photo_box {
    margin: 3px 6px;
}

.photo_image {
    /* object-fit: cover; */
    /* this controls the width of the columns */
    max-width: 400px;
}

@media screen and (max-width: 1665px) and (min-width: 319px){
    .photogallery_section {
        background-color: rgb(255, 255, 255);
    }
}


.brand_page {    
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-self: stretch;
    height: 100vh;
    width: 100vw;
}
.brand_page_background {
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.brand_pg_img {
    width: 1200px; 
    max-height: 800px;   
}
.brand_page_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    align-content: stretch;
    width: 100vw;
    height: 100vh;
    margin: 25% auto;
}
.brand_headline {
    font-family: 'Krona One', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 90px;
    line-height: 1;
    width: 100%;
    /* text-align: left; */
    padding: 0 0 50px 0;
    text-shadow: 3px 3px black;
}
.brand_content {
    font-family: 'Raleway', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 18px;
    width: 50vw;;
    padding: 80px;
    background-color: rgba(0, 0, 0, 0.6);
}
.brand_text {
    padding-bottom: 20px;
}
.brand_home_button {
    font-family: 'Raleway', sans-serif;
    background-color: transparent;
    border-radius: 8px;
    border: 1px rgb(255,255,255) solid;
    color: rgb(255,255,255);
    font-size: 16px;
    /* font-weight: 600; */
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin-bottom: 50px;
   
    padding: 8px 16px;
    line-height: 3;
}
.brand_home_button:hover {
    background:rgb(255,255,255);
    color: rgb(0,0,0); 
    cursor: pointer;
}
.footer_section {
    width: 100%;
}

/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px) {
    .brand_page {    
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content:flex-start;
        align-items: center;
        align-self: center;
    }
    .brand_page_container {
        margin: 5% auto;
        justify-content:flex-start;        
        height: 120vh;
        width: 90vw;
        padding: 0 0 300px;
    }    
    .brand_headline {      
        font-size: 42px;
        line-height: 1.2;
        width: 70vw;
        text-shadow: 3px 3px black;
        padding: 12px;
    }
    .brand_content {
        font-size: 14px;
        width: 80vw;
        padding: 12px 12px 24px; 
        line-height: 1.2;     
    }
    .brand_home_button {
        font-size: 12px;
    }
}
