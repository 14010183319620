@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
@import url('https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
body {
    background-color: rgb(18,18,18);
}

/* ------- TOP HEADER SECTION --------------------------------- */
.pg_grid_section {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
    height: 100vh;
    width: 100vw;
}
.pg_grid_header {
    font-family: 'Monoton', cursive;
    color: rgb(255,255,255);
    font-size: 84px;
    line-height: 1.2;
    letter-spacing: 24px;
    word-spacing: 30px;
    margin: 0 auto;
    padding: 40px 20px 20px;
    position: relative;
}
.pg_grid_secondline {
    font-family: 'Poiret One', cursive;
    color: rgb(255,255,255);
    font-size: 40px;
    letter-spacing: 1.4px;
    word-spacing: 4px;
    margin: 0 auto;
    padding: 0 10px 40px;
}

/* ------ BOTTOM PHOTO GALLERY QUOTE SECTION ----------------------------------- */
.pg_grid_homebtn_space {    
    display: flex;    
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
	align-items: center;
	align-content: center;
    height: 100vh;  
    margin: 10vh;  
}
.pg_grid_quote {
    font-family: 'Poiret One', cursive;
    letter-spacing: 4px;
    line-height: .9;
    color: rgb(167, 163, 163);
    font-size: 84px;
    text-align: center;
    margin: 48px 0 12px;
}
.pg_grid_quote_source {
    font-family: 'Raleway', sans-serif;
    font-size: 64px;
    font-style: normal;
    color: rgb(167, 163, 163);
    margin: 0 48px 48px;
}
.pg_grid_home_button {
    display: inline;
    font-family: 'Raleway', sans-serif;
    background-color: transparent;
    border-radius: 8px;
    border: 1px rgb(255,255,255) solid;
    color: rgb(255,255,255);
    font-size: 16px;
    transition: all 0.3s;
    margin: 44px auto;
    padding: .7rem 1.5rem;
    line-height: 1;
}
.pg_grid_home_button:hover {
    background:rgb(255,255,255);
    color: rgb(0,0,0); 
    cursor: pointer;
}
.footer_style {
    width: 100%;
}

/* -------------- DESKTOP PHOTO GALLERY GRID CODE - (Min Width 1500px)------------------------------------- */
.pg_grid_container {
    display: grid;
    grid-template-columns: repeat(8,1fr);
    grid-auto-rows: 400px;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    justify-items: stretch;
    align-items: stretch;
    margin: 0 10px;
}
.pg_grid_box {
    width: 100%;
    height: 100%;
    /* overflow:hidden hides parts of images that cross over another */
    overflow: hidden;
    position: relative;
}
.pg_grid_image {
    width: 100%;
    height: 100%;
    /* object-fit:cover makes image auto size itself and fills in space */
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}
.pg_grid_image:hover {
    /* makes images pop bigger */
    transform:scale(1.25);
    transition: .2s ease-in-out;
}

/* ----------- GALLERY GRID SPANNING CONTROLS ---- DEFAULT DESKTOP SCREENS --------- */
.p-1 {
    grid-column: span 5;
    grid-row: span 1;
}
.p-2 {
    grid-column: span 1;
    grid-row: span 1;
}
.p-3 {
    grid-column: span 2;
    grid-row: span 2;
}
.p-4 {
    grid-column: span 3;
    grid-row: span 3;
}
.p-5 {
    grid-column: span 3;
    grid-row: span 1;
}
.p-6 {
    grid-column: span 5;
    grid-row: span 2;
}
.p-7 {
    grid-column: span 2;
    grid-row: span 1;
}
.p-8 {
    grid-column: span 6;
    grid-row: span 2;
}
.p-9 {
    grid-column: span 2;
    grid-row: span 1;
}
.p-10 {
    grid-column: span 5;
    grid-row: span 2;
}
.p-11 {
    grid-column: span 1;
    grid-row: span 1;
}
.p-12 {
    grid-column: span 2;
    grid-row: span 1;
}
.p-13 {
    grid-column: span 3;
    grid-row: span 4;
}
.p-14 {
    grid-column: span 3;
    grid-row: span 1;
}
.p-15 {
    grid-column: span 2;
    grid-row: span 1;
}
.p-16 {
    grid-column: span 5;
    grid-row: span 2;
}

.p-18 {
    grid-column: span 8;
    grid-row: span 2;
}
.p-19 {
    grid-column: span 3;
    grid-row: span 1;
}
.p-20 {
    grid-column: span 5;
    grid-row: span 2;
}
.p-21 {
    grid-column: span 3;
    grid-row: span 3;
}
.p-22 {
    grid-column: span 2;
    grid-row: span 1;
}
.p-23 {
    grid-column: span 3;
    grid-row: span 2;
}
.p-24 {
    grid-column: span 2;
    grid-row: span 1;
}
.p-25 {
    grid-column: span 6;
    grid-row: span 2;
}
.p-26 {
    grid-column: span 2;
    grid-row: span 2;
}
.p-27 {
    grid-column: span 6; 
    grid-row: span 2;
}
.p-28 {
    grid-column: span 2;
    grid-row: span 3;
}
.p-29 {
    grid-column: span 4;
    grid-row: span 2;
}
.p-30 {
    grid-column: span 2;
    grid-row: span 1;
}
.p-31 {
    grid-column: span 4;
    grid-row: span 1;
}
.p-32 {
    grid-column: span 8;
    grid-row: span 3;
}





/* --------------- MEDIA QUERIES ----------------------------------------------------- */
/* --------------- TABLET MEDIA QUERIES---------------------------- */
@media screen and (max-width: 1500px) and (min-width: 800.5px){      
    .pg_grid_header {
        font-size: 64px;
        line-height: 1;
        letter-spacing: 12px;
        word-spacing: 20px;
        margin-bottom: 20px;
    }
    .pg_grid_container {
        grid-template-columns: repeat(3,1fr);
        grid-auto-rows: 400px;
        
    }
    .pg_grid_homebtn_space {    
        height: 100%;
        padding: 14px;
        margin: 5% auto;
    }
    .pg_grid_quote {
        font-size: 36px;
        line-height: 1.2;
    }
    .pg_grid_quote_source {
        font-size: 20px;
        font-style: normal;
        color: rgb(167, 163, 163);
        margin: 12px 0 0 0;
    }
    .pg_grid_home_button {
        margin: 44px auto;
        padding: .7rem 1.5rem;     
    }
    /* ---------- TABLET SPANNING WIDTH CONTROLS ------- */
    .p-1 {
        grid-column: span 3;
        grid-row: span 1;
    }
    .p-2 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-3 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-4 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-5 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-6 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-7 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-8 {
        grid-column: span 3;
        grid-row: span 1;
    }
    .p-9 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-10 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-11 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-12 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-13 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-14 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-15 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-16 {
        grid-column: span 3;
        grid-row: span 1;
    }
    .p-17 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-18 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-19 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-20 {
        grid-column: span 3;
        grid-row: span 1;
    }
    .p-21 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-22 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-23 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-24 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-25 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-26 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-27 {
        grid-column: span 2; 
        grid-row: span 2;
    }
    .p-28 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-29 {
        grid-column: span 3;
        grid-row: span 1;
    }
    .p-30 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-31 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-32 {
        grid-column: span 3;
        grid-row: span 1;
    }
  
}

/* ---------- LARGE PHONE MEDIA QUERIES--------------------------------- */
@media screen and (max-width: 800px) and (min-width: 575.5px){  
    .pg_grid_header {
        font-size: 36px;
        line-height: 1;
        letter-spacing: 12px;
        word-spacing: 20px;
        margin-bottom: 20px;
    }
    .pg_grid_container {
        grid-template-columns: repeat(2,1fr);
        grid-auto-rows: 400px;        
    }
    .pg_grid_homebtn_space {    
        height: 100%;
        padding: 14px;
        margin: 5% auto;
    }
    .pg_grid_quote {
        font-size: 24px;
        line-height: 1.2;
    }
    .pg_grid_quote_source {
        font-size: 18px;       
    }
    .pg_grid_home_button {
        margin: 44px auto;
        padding: .7rem 1.5rem;       
    }
  /* ---------- LARGE PHONE SPANNING WIDTH CONTROLS ------- */
    .p-1 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-2 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-3 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-4 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-5 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-6 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-7 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-8 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-9 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-10 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-11 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-12 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-13 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-14 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-15 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-16 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-17 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-18 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-19 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-20 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-21 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-22 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-23 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-24 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .p-25 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-26 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-27 {
        grid-column: span 1; 
        grid-row: span 1;
    }
    .p-28 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-29 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-30 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-31 {
        grid-column: span 2;
        grid-row: span 1;
    }
    .p-32 {
        grid-column: span 2;
        grid-row: span 2;
    }
       
}

/* ----------- SMALL PHONE MEDIA QUERIES------------------------------------- */
@media screen and (max-width: 575px) and (min-width: 319px){  
    .pg_grid_header {
        font-size: 32px;
        line-height: 1.2;
        letter-spacing: 12px;
        text-align: center;
    }
    .pg_grid_secondline {
        font-size: 14px;
        line-height: 1;
        letter-spacing: 1.2px;
        margin: 0 auto;
        padding: 0 10px 24px;
    }
    .pg_grid_container {
        grid-template-columns: 1fr;
        grid-auto-rows: 400px;        
    }
    .pg_grid_homebtn_space {    
        height: 100%;
        padding: 24px;
        margin: 20px;
    }
    .pg_grid_quote {
        font-family: 'Raleway', sans-serif;
        font-size: 24px;
        line-height: 1.2;
    }
    .pg_grid_quote_source {
        font-size: 18px;      
    }
    .pg_grid_home_button {
        margin: 12px auto;
        padding: .5rem 1.5rem;  
    }   
 
  /* ---------- SMALL PHONE SPANNING WIDTH CONTROLS ------- */
    .p-1 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-2 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-3 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-4 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-5 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-6 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-7 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-8 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-9 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-10 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-11 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-12 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-13 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-14 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-15 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-16 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-17 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-18 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-19 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-20 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-21 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-22 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-23 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-24 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-25 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-26 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-27 {
        grid-column: span 1; 
        grid-row: span 1;
    }
    .p-28 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-29 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-30 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-31 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .p-32 {
        grid-column: span 1;
        grid-row: span 1;
    }
        
}