@import url('https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');


.portfolio_section {
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    /* height: 100vh; */
    width: 100vw;
    background-color: rgb(255, 255, 255);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
}
.portfolio_section_title {
    font-family: 'Krona One', sans-serif;
    color: rgba(230, 213, 67, 1);
    text-align: center;
    font-size: 72px;
    line-height: 1;
    padding-top: 24px;
    width: 100vw;
    text-shadow: rgba(0, 0, 0, 0.7) 3px 3px;
}
.portfolio_section_subtitle {
    font-family: 'Raleway', sans-serif;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 26px;
    line-height: 1;
    width: 100vw;
    text-shadow: rgba(0, 0, 0, 0.7) 2px 2px;
}
.portfolio_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-width: 1400px;
    padding: 10% 0 10%;
}
.portfolio_box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 6px;
    max-width: 550px;
    height: 500px;
    padding: 12px;
    margin: 30px 25px;
}
.portfolio_box_title {
    font-family: 'Krona One', sans-serif;
    color: rgb(230, 213, 67);
    font-size: 22px;
    line-height: 1.1;
    letter-spacing: 1.5px;
    margin: 12px 24px 12px 24px;
}
.portfolio_box_content {
    font-family: 'Raleway', sans-serif;
    color: white;
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 1.2px;
    margin: 6px 24px 12px;
}
.portfolio_box_image {
    margin: 24px;
    max-width: 480px;
    border: 8px white solid;
    border-radius: 8px;
}

@media screen and (max-width: 480px) and (min-width: 319px) {
    .portfolio_section_title {
        font-size: 36px;      
    }
    .portfolio_section_subtitle {
        font-size: 22px;
    }
    .portfolio_box {
        padding: 8px;
        margin: 10%;
        max-height: 384px;
        width: 80vw;
    }
    .portfolio_box_title {
        font-size: 20px;
        line-height: 1;
        margin: 6px;
    }
    .portfolio_box_content {
        font-size: 14px;
        line-height: 1;
        margin: 6px;
    }
    .portfolio_box_image {
        margin: 6px;
        width: 70vw;
    }
}