@import url('https://fonts.googleapis.com/css?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');

.message3_section {
    /* background-color: rgba(0, 0, 0); */
    /* background-image: linear-gradient(to right, rgb(5, 4, 46), rgb(4, 32, 58)); */
    background-image: linear-gradient(to right, rgb(24, 1, 7) , rgb(39, 3, 44));
    height: 800px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center; 
}

.message3_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center; 
}

.message3_first_line {
    font-family: 'Prata', serif;
    color: rgb(255, 255, 255);
    font-size: 40px;
    line-height: 1.2;
    text-align: center;
}

.message3_second_line {
    font-family: 'Krona One', sans-serif;
    color: rgba(230, 213, 67, 1);
    font-size: 80px;
    line-height: 1.1;
    text-align: center;
    margin-bottom: 6px;
}
 .message3_third_line {
    font-family: 'Prata', serif;
    line-height: .8;
    color: white;
    font-size: 40px;
    line-height: .5;
    text-align: center;
 }
 @media screen and (max-width: 767.5px) and (min-width: 480px){
    .message3_section {
        height: 560px;
    }

    .message3_first_line {
        font-size: 30px;
        padding-top: 30px;
        letter-spacing: 1.5px;
        word-spacing: 8px;
    }
    
    .message3_second_line {
        font-size: 50px;
        letter-spacing: .8px;
    }
     .message3_third_line {
        font-size: 30px;
        padding-bottom: 30px;
        letter-spacing: 1.5px;
        word-spacing: 8px;
     }
    
}
@media screen and (max-width: 479.5px) and (min-width: 319px) {
    .message3_section {
        height: 480px;
    }
    .message3_first_line {
        font-size: 20px;
        padding-top: 30px;
        letter-spacing: 1px;
    }
    
    .message3_second_line {
        font-size: 30px;
        letter-spacing: 1px;
    }
     .message3_third_line {
        font-size: 20px;
        padding-bottom: 30px;
        letter-spacing: .7px;
     }
}